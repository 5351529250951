const React = require('react');
const Layout = require('./src/components/layout').default
const { PageASideProvider } = require('./src/context/pageASide')
const { MiniPlayerProvider } = require('./src/context/miniPlayer')
const { QueryClient, QueryClientProvider } = require('react-query')
const { ReactQueryDevtools } = require('react-query/devtools')

const queryClient = new QueryClient()

exports.wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  );
};
exports.wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
      <PageASideProvider>
        <MiniPlayerProvider>
          {element}
        </MiniPlayerProvider>
      </PageASideProvider>
    </QueryClientProvider>
  );
}