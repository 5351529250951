/* global _paq */
import { navigate } from "gatsby"
import { isMatomoEnabled } from '../data/global';

const isBrowser = typeof window !== `undefined`

const getUser = () =>
  window.localStorage.veUser ? JSON.parse(window.localStorage.veUser) : {}

const setUser = user => (window.localStorage.veUser = JSON.stringify(user))

const capitalizeFirstLetter = string => {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isLoggedIn = () => {

  if (!isBrowser) return false

  const user = getUser()
  const firstNameArray = user?.firstName?.split(" ")
  const lastNameArray = user?.lastName?.split(" ")

  window.localStorage.userName = firstNameArray?.map(individualName => capitalizeFirstLetter(individualName)).join(" ") + " " + lastNameArray?.map(individualName => capitalizeFirstLetter(individualName)).join(" ")
  return !!user.isLoggedIn
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return;
  if (isMatomoEnabled) {
    _paq.push(['resetUserId']);
    _paq.push(['MediaAnalytics::disableMediaAnalytics']);
  }
  fetch(process.env.API_URL + "/logout", { method: "POST" })
  console.log(`Ensuring the \`veUser\` property exists.`)
  setUser({})
  callback()
}

export const clearClientData = () => {
  if (!isBrowser) return;
  if (isMatomoEnabled) {
    _paq.push(['resetUserId']);
    _paq.push(['MediaAnalytics::disableMediaAnalytics']);
  }
  console.log(`Ensuring the \`veUser\` property exists.`)
  setUser({})
  navigate(`/`)
}
