import React, { useState, createContext } from 'react'

export const miniPlayerContext = createContext()

export const MiniPlayerProvider = ({ children }) => {

    const [miniPlayerState, setminiPlayerState] = useState({
        showVideoAtBottom: false,
        videoUrl: "",
        videoTitle: "",
        activeSlideFromMP: 0
    })

    return (
        <miniPlayerContext.Provider value={[miniPlayerState, setminiPlayerState]}>
            {children}
        </miniPlayerContext.Provider>
    )
};
