/* global _paq */
import { useEffect} from "react"
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faSignOutAlt,
  faUser,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { isLoggedIn, logout } from '../services/auth';
import { logo, logoalt, isMatomoEnabled } from '../data/global';

const Header = ({ siteTitle }) => {
  useEffect(() => {
    if(isLoggedIn() && isMatomoEnabled){
      _paq.push(['setUserId', JSON.parse(localStorage.veUser).userId]);
    }
  }, [])

  return(
  <header className="header-block background-color-primary-1">
    <div className="container-fluid">
      <div className="header-content d-xs-flex xs-between xs-middle width-full">
        <div className="logo-box">
          <Link to="/">
            <img src={logo} alt={logoalt} />
          </Link>
        </div>
        <div className="event-title xs-margin-right-auto d-xs-none d-sm-block">
          <h1 className="text-size-h3 color-monochrome-1 font-primary-bold mb-0 ml-40">
            {siteTitle}
          </h1>
        </div>
        <div className="text-size-extra-large color-monochrome-1 margin-left-auto">
          <Link
            to={isLoggedIn() ? '/dashboard' : '/'}
            // if there is new class added please make change in length comparator
            className="text-size-extra-large color-monochrome-1 d-xs-flex xs-middle"
          >
            <FontAwesomeIcon icon={faHome} />
            <span className="d-xs-none d-sm-block ml-5">Home</span>
          </Link>
        </div>
        <div className="text-size-extra-large d-xs-flex color-monochrome-1 ml-20">
          <Link
            to="/help/"
            // if there is new class added please make change in length comparator
            className="color-monochrome-1 d-xs-flex xs-middle"
            activeClassName="active"
            onClick={(event) => {
              if (event.currentTarget.classList.length == 4) {
                window.location.reload();
              }
            }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span className="ml-5 d-xs-none d-sm-block ml-5">Help</span>
          </Link>
        </div>

        {isLoggedIn() && (
          <div className="ml-20 d-xs-flex xs-middle text-size-extra-large color-monochrome-1">
            <FontAwesomeIcon icon={faUser} />
            <span className="d-xs-none d-sm-block font-secondary-regular ml-5">
              {window.localStorage.userName}
            </span>
          </div>
        )}

        {isLoggedIn() ? (
          <div className="ml-20">
            <Link
              to="/"
              className="text-size-extra-large color-monochrome-1 d-xs-flex xs-middle _a_logout"
              onClick={(event) => {
                event.preventDefault();
                logout(() => navigate('/'));
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span className="d-xs-none d-sm-block font-secondary-regular ml-5">Logout</span>
            </Link>
          </div>
        ) : ''}
      </div>
    </div>
  </header>
);}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
